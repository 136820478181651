import React from "react";
import Chero from "../components/chero";
import Head from "../components/head";
import Is2 from "../components/pages/index/is2/is2";
import S1 from "../components/pages/services/s1/s1";
const Clients = () => (
  <>
    <Head
      title="Services"
      dis="If you are looking for a new role, re-locating or simply want to work in a new department, our dedicated nursing team focus on your requirements and will work with you to help make the transition as smooth as possible"
    />
    <Chero herotitle="Services" />
    <S1 />
    <Is2 />
  </>
);

export default Clients;
