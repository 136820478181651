import React from "react";
import "./s1.scss";

export default () => {
  return (
    <div className="s1 container">
      <div className="s1-left">
        <blockquote>
          <p>
            Operating in the UK, Premise Healthcare has extensive experience in
            delivering to a variety of clients including NHS Trusts, PCTs,
            police constabularies, prisons, third sector and private sector
            organisations.
          </p>
        </blockquote>

        <p>
          If you are looking for a new role, re-locating or simply want to work
          in a new department, our dedicated nursing team focus on your
          requirements and will work with you to help make the transition as
          smooth as possible.
        </p>
      </div>
      <div className="s1-right">
        <div></div>
      </div>
    </div>
  );
};
